import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../../Button';
import { removeCartItem, addToCartNft, nftCartList } from '../../../lib/NFT'
import styles from './styles.module.scss';
import { getProfile } from '../../../lib/User'
import UseUser from '../../../Contexts/User/useUser'
import Swal from 'sweetalert2'
import useNFT from '../../../Contexts/NFT/useNFT'
import UseAuth from '../../../Contexts/Auth/useAuth'
import { getDeviceId } from '../../../Hooks/getDeviceId';

const ReplaceCartModal = ({show, onHide, token, blockchain, is_cart, setIs_cart, bit, nftTopCollectionFunc, sortBy, blockchainData}) => {
  const { authState } = UseAuth()

  const { updateUserProfile } = UseUser()
  const { updateNftDetsilsinList, updateCartListData, getCartListData, getNftDetailsData, updateTopCollectionData } = useNFT()

  const getAndUpdateUserData = async () => {
    const userResponse = await getProfile()
    if (userResponse.status === 200) {
      updateUserProfile({
        ...userResponse?.data?.data,
      })
    }
  }


  const nftCartListAPI = async () => {
      const req = {
        device_id: getDeviceId()
      }
      const response = await nftCartList(req);
      if (response?.status === 200) {
        getCartListData(response?.data?.data)
        
      }
  };


  const onRemoveData = async() => {
        const data = {
          token: "",
          blockchain:"",
          is_removeAll: 1,
          device_id: getDeviceId()
         }
        const removeResponse = await removeCartItem(data)
        if (removeResponse.status === 200) {
          nftCartListAPI()
          getAndUpdateUserData()
          removeResponse?.data?.data?.map((data) => {
            updateNftDetsilsinList(data)
            getNftDetailsData(data);
            updateCartListData(data)
            updateTopCollectionData(data)
          })

          if (typeof token !== 'string' && token?.length > 0) {
            token?.map(async(item) => {
              const data = {
                token: item,
                blockchain: blockchain,
                device_id: getDeviceId(),
              };
              const response = await addToCartNft(data);
              if (response.status === 200) {
                updateNftDetsilsinList(response.data?.data);
                getAndUpdateUserData();
                
                if(bit === 1) {
                  setIs_cart(is_cart === 2 ? 1 : 2 ?? 1);
                } else if(bit ===2) {
                  nftTopCollectionFunc(sortBy, blockchainData);
                }
                Swal.fire({
                  icon: "success",
                  // title: "Oops...",
                  text: `${response.data.message}`,
                  timer: 5000,
                });
                onHide()
              }
            })
            
          } else {
            const data = {
              token: token,
              blockchain: blockchain,
              device_id: getDeviceId(),
            };
            const response = await addToCartNft(data);
            if (response.status === 200) {
              updateNftDetsilsinList(response.data?.data);
              getAndUpdateUserData();
              
              if(bit === 1) {
                setIs_cart(is_cart === 2 ? 1 : 2 ?? 1);
              } else if(bit ===2) {
                nftTopCollectionFunc(sortBy, blockchainData);
              }
              Swal.fire({
                icon: "success",
                // title: "Oops...",
                text: `${response.data.message}`,
                timer: 5000,
              });
              onHide()
            }
          }
      
        } else {
          Swal.fire({
            icon: 'warning',
            title: response?.data?.message,
            showConfirmButton: false,
            timer: 1500,
          })
        }
      }

  return (
    <>
      <Modal
        show={show}
        onHide={() => onHide()}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className={`${styles.nomodalborder}`}>
          <Modal.Title id="contained-modal-title-vcenter">
            <h1 className={`${styles.title}`}>Replace Cart Item?</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${styles.modalbody}`}>
          <div className={`${styles.replace_separate}`}>
            <h3 className={`${styles.replace_description}`}>Your cart contains item in {blockchain === 'ethereum' ? 'MATIC' : 'ETH'}, Do you wish to discard the selection and add the new item in  {blockchain === 'ethereum' ? 'ETH' : 'MATIC'}?</h3>
          </div>
        </Modal.Body>
        <Modal.Footer className={`${styles.nomodalborder} mb-4`}>
          <Button tag={'md'} onClick={() => onRemoveData()}>
            Yes
          </Button>
          <Button
            varient={"secondary_nooutline"}
            onClick={() => onHide()}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReplaceCartModal;
