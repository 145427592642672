import React from "react";
import styles from "./styles.module.scss";

const BTN = (props) => {
  return (
    <button
      className={`
                ${styles.btn}
                ${props.varient ? styles[`${props.varient}`] : styles.primary}
                ${props.active ? styles.active : ""}
                ${props.className ? props.className : ""}
                ${props.tag ? styles[`${props.tag}`] : styles.md}
                ${props.className}
            `}
      onClick={props.onClick ?? null}
      disabled={props.disabled}
      type={props.type ?? "button"}
    >
      {props.children}
    </button>
  );
};

export default BTN;
